.homeBody {
  /* background-color: #f0f8ff; */
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 60px;
}
.homeBody1 {
  background-image: linear-gradient(#ede7d9fa, #ede7d9fa), url('./components/svg1.svg');
  background-size: 250px;
  background-repeat: repeat;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 60px;
}
.loginInput {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  outline: none;
  padding: 5px;
  font-size: 14px;
}
.valueButton {
  border: 2px #C40000 solid;
  width: 80px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  padding: 3px;
  box-sizing: border-box;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden !important;
  z-index: 10;
  background-color: #C40000;
}
.valueButton:hover {
  color: white;
}

.valueButton::before {
  content: '';
  width: 0%;
  height: 100%;
  display: block;
  background: #C40000;
  position: absolute;
  transform: skewX(-20deg);
  left: -10%;
  opacity: 1;
  top: 0;
  z-index: -12;
  transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
  box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.valueButton::after {
  content: '';
  width: 0%;
  height: 100%;
  display: block;
  background: #001d6b;
  position: absolute;
  transform: skewX(-20deg);
  left: -10%;
  opacity: 0;
  top: 0;
  z-index: -15;
  transition: all .4s cubic-bezier(.2, .95, .57, .99);
  box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.valueButton:hover::before {
  opacity: 1;
  width: 116%;
}

.valueButton:hover::after {
  opacity: 1;
  width: 120%;
}
body {
}
.mapView {  
  background-color: #5c5c5c;
  background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingShimmer;
  animation-timing-function: linear;
  background-color: #efefef;
  background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
  background-repeat: no-repeat;
  background-size: 50%;
  aspect-ratio: 4/2;

}
.mapView iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  
}
@keyframes loadingShimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
.cropperContainer {
  position: unset;
}
.cropArea {
  position: unset;
}