#testimonials .card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0 0 10px 10px rgba(184, 184, 184, 0.171);
    width: 500px;
    margin: 15px 20px;
    height: 220px;
    justify-content: center;
    flex: 1;
}

#testimonials .cardBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 2px solid #e4e4e494;
}
#quoteIcon {
    all: unset;
    width: 35px;
    height: 35px;
    
}
#testimonials svg[class~='bi-facebook'] {
    /* color: #0165E1; */
    width: 25px;
    height: 25px;
    align-self: flex-end;
}

#testimonials svg[class~='bi-instagram'] {
    /* color: #E1306C; */
    width: 23px;
    height: 23px;
    align-self: flex-end;

}

#testimonials .cardName {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14.5px;
    font-weight: 500;
    margin-block: 5px;
    margin-bottom: -2px;
}

#testimonials .cardData {
    font-family: Arial, Helvetica, sans-serif;
    color: #868686;
    font-size: 14px;
}

#testimonials .cardReview {
    font-family: Arial, Helvetica, sans-serif;
    color: #8a8a8a;
    font-size: 14.5px;
}

#testimonials svg[class~='bi-star-fill'] {
    color: #FFB703;
    width: 15px;
    height: 15px;
}

#testimonials .cardStars {
    display: flex;
    flex-direction: row;
}

/* Media Queries */
@media screen and (max-width: 700px) {
    #testimonials .card {
        width: 70vw;
        margin: 15px;
    }
}

@media screen and (max-width: 400px) {
    #testimonials .cardName,
    #testimonials .cardData,
    #testimonials .cardReview {
        font-size: 12.5px;
    }
}
