#aboutApp .phone{
	width: 335px;
	aspect-ratio: 3/5.9;
	/* background-image: linear-gradient(to right, rgb(212, 92, 255) , rgb(97, 180, 253)); */
	border-radius: 3rem;
	border: 12px solid #e6e6e6;
	display: grid;
	justify-content: center;
	/* box-shadow: 0 0 50px 18px rgba(250, 241, 255, 0.599); */
    position: relative;
    overflow: hidden;
    background: #ffffff00;
    /* box-shadow: 0 0 50px 18px #edcdff52; */
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;

}
#aboutApp .phoneContainer {
    width: 335px;
    margin-inline: 50px;
}

#aboutApp .phone img{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    height: 100%;
}



#aboutApp .notch{
	width: 120px;
    aspect-ratio: 5/1;
	background-color: #e6e6e6;
	border-radius: 0 0 15px 15px;
	transform: translateY(-2px);
    z-index: 1;
}
#aboutApp .phones {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    background: transparent;
}
#aboutApp .text {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    padding-inline: 20px;
    background: transparent;
    /* color: #b4b4b4; */
}

@media screen and (max-width: 900px) {
    #aboutApp .phoneContainer{
        width: 290px;
        margin-inline: 25px;

    }
    #aboutApp .phone{
        width: 290px;
        border-radius: 2.7rem;
        border: 14px solid #e6e6e6;



    }
    #aboutApp .notch{
        width: 120px;
        
    }
    
}
@media screen and (max-width: 630px) {
    #aboutApp .phoneContainer{
        width: 230px;
        margin-inline: 20px;

    }
    #aboutApp .phone{
        width: 230px;
        border-radius: 2.5rem;
        border: 13px solid #e6e6e6;



    }
    #aboutApp .notch{
        width: 75px;
    }
    #aboutApp .text {
        font-size: 14px; 
        padding-inline: 6px;
       
    }
    
}
@media screen and (max-width: 450px) {
    #aboutApp .phoneContainer{
        width: 200px;
        margin-inline: 15px;

    }
    #aboutApp .phone{
        width: 200px;
        border-radius: 2rem;
        border: 10px solid #e6e6e6;
        background-repeat: repeat-y;

    }
    #aboutApp .notch{
        width: 60px;
    }
    #aboutApp .text {
        font-size: 12px; 
        padding-inline: 6px;
       
    }
    
}