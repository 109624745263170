.dashboardContainer {
    height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

> .title {
    font-weight: 700;
    font-size: 24px;
}

.firstStats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;

    @media screen and (width < 1075px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (width < 390px) {
        grid-template-columns: repeat(1, 1fr);

    }
    .stat {
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        padding: 15px;
        .header {
            all: unset;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5px;
            svg {
                color: rgb(0, 223, 0);
                width: 20px;
                height: 20px;
            }
        }
        .title {
            font-size: 14px;
            font-weight: 400;
        }
        .statValue {
            font-size: 25px;
            font-weight: 600;
        }
        .difference {
            color: grey;
            font-size: 12px;
        }
    }
}
.secondStats {
    display: flex;
    flex-direction: row;
    gap: 15px;


    @media screen and (width < 1105px) {
        flex-direction: column;
    }

    .cardTitle {
        font-weight: 600;
    }
    .chartContainer {
        max-height: 310px;
        height: 100%;
        width: 100%;
    }
    .chartTTContent {
        box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
        background-color: white;
        width: 100px;
        height: 50px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        gap: 5px;
        svg {
            width: 16px;
            height: 16px;
            color: #008cff;
        }
        
    }

    .topNews {
        display: flex;
        flex-direction: column;
        gap: 25px
    }
    .news {
        display: flex;
        gap: 15px;
        flex-direction: row;
        align-items: center;
        .imageContainer {
            width: 55px;
            height: 55px;
            min-height: 55px;
            min-width: 55px;
            border-radius: 7px;
            overflow: hidden;
            background-color: #5c5c5c;
            background-image: linear-gradient(90deg, rgba(91,91,91,1.00) 0%,rgba(112,112,112,1.00) 40%,rgba(91,91,91,1.00) 80%,rgba(91,91,91,1.00) 97%);
            animation-duration: 1s;
              animation-fill-mode: forwards;
              animation-iteration-count: infinite;
              animation-name: loadingShimmer;
              animation-timing-function: linear;
              background-color: #efefef;
              background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%,rgba(221,221,221,1.00) 40%,rgba(239,239,239,1.00) 80%,rgba(239,239,239,1.00) 97%);
              background-repeat: no-repeat;
              background-size: 50%;
            @keyframes loadingShimmer {
                0% {
                  background-position: -100% 0;
                }
              
                100% {
                  background-position: 200% 0;
                }
              }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }
        .info {
            display: flex;
            flex-direction: column;

            .views {
                color: grey;
                font-size: 12px;
            }
            .title {
                font-size: 13px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* Number of lines to display */
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                    opacity: .8;
                }
            }
            
            .date {
                display: none;
            }
        }
    }
}


.thirdStats {
    display: flex;
    flex-direction: row;
    gap: 15px;

    @media screen and (width < 1105px) {
        flex-direction: column;
    }

    .cardTitle {
        font-weight: 600;
    }
    .topNews {
        display: flex;
        flex-direction: column;
        gap: 25px
    }
    .news {
        display: flex;
        gap: 15px;
        flex-direction: row;
        align-items: center;
        .imageContainer {
            width: 55px;
            height: 55px;
            min-height: 55px;
            min-width: 55px;
            background-color: lightgrey;
            border-radius: 7px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }
        .info {
            display: flex;
            flex-direction: column;

            .views {
                color: grey;
                font-size: 12px;
            }
            .title {
                font-size: 13px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* Number of lines to display */
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                    opacity: .8;
                }
            }
            
            .date {
                display: none;
            }
        }
    }
    .companyInfo {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .row {
            display: flex;
            gap: 10px;
            align-items: flex-start;
            padding: 12px 0;
            border-top: 1px solid #dfdfdf;


            svg {
                color: grey;
                margin-top: 5px;
                width: 14px;
                height: 14px;
            }
            .info {
                display: flex;
                flex-direction: column;
                font-size: 13px;
                gap: 5px;
                .title {
                    color: grey;
                    
                }
                .val {
                    font-weight: 500;
                }
            }
        }
    }
}

    .section {
        border-radius: 7px;
        overflow: hidden;
        flex: 1.2;
        border: 1px solid #dfdfdf;
        display: flex;
        flex-direction: column;
        @media screen and (width < 900px) {
            flex: none;
            
        }
        
        
        .header {
            all: unset;
            background-color: #f7f7f7;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 8px 10px;
            border-bottom: 1px solid #dfdfdf;
            flex-wrap: wrap;

            .label {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 10px;
                .title {
                    font-size: 13px;
                    font-weight: 500;
                }
                svg {
                   width: 13px;
                   height: 13px; 
                }
            }
            .editCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                border: 1px solid #dfdfdf;
                padding: 0 10px;
                border-radius: 3px;
                font-size: 13px;
                cursor: pointer;
                &:hover {
                    opacity: 0.7;
                }
                svg {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
}