
#checkout {
    margin-top: 80px;
    margin-inline: 10px;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: calc(100% - 20px);
    max-width: 1600px;
}
#checkout .top {
    max-width: 1600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: center;
}
#checkout .top .step {
    border: 1px solid #cecece;
    font-size: 12.5px;
    color: grey;
    border-radius: 4px;
    padding-block: 7px;
    padding-inline: 15px;
    text-align: center;    
}
#checkout .top .step span {
    font-weight: 500;

}

#checkout .progression {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    gap: 4px;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    padding:5px;

}

#checkout .progression .section {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #f0f0f0;
    font-size: 14px;
    padding-left: 20px;
    position: relative;
    transition: 0.2s ease-in-out;
}
#checkout .progression .section::after {
    content: "";
    position: absolute;
    /* border-top: 10px solid white;
	border-left: 20px solid #e9e9e9;
    
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
	border-bottom: 10px solid white; */
    border-radius: 0 100px 100px 0;
    width: 20px;
    height: 25px;
    right: -20px;
    z-index: 5;
    background-color: #f0f0f0;
    border-top: 5px solid white;
    border-bottom: 5px solid white;
    border-right: 5px solid white;

}
#checkout .progression .section:hover {
    background-color: #c40000;
    color: white;
}
#checkout .progression .section:hover::after {
    background-color: #c40000;
    
}
#checkout .progression .section1 {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #c40000;
    font-size: 14px;
    padding-left: 20px;
    position: relative;
    transition: 0.2s ease-in-out;
    color: #ffffff;
}
#checkout .progression .section1::after {
    content: "";
    position: absolute;
    /* border-top: 10px solid white;
	border-left: 20px solid #e9e9e9;
    
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
	border-bottom: 10px solid white; */
    border-radius: 0 100px 100px 0;
    width: 20px;
    height: 25px;
    right: -20px;
    z-index: 5;
    background-color: #c40000;
    border-top: 5px solid white;
    border-bottom: 5px solid white;
    border-right: 5px solid white;

}
#checkout .list {
    /* display: flex;
    flex-direction: column; */
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
}
@media screen and (max-width: 1000px) {
    #checkout .list {
        
        grid-template-columns: repeat(1, 1fr);
    }
    
}

#checkout .horario {
    box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.158);
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 20px;
}
#checkout .horarioTitle {
    font-size: 15px;
    font-weight: 600;
}
#checkout .arrivals {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#checkout .arrival {
    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow: hidden;
}

#checkout .arrival span {
    color: grey;
    font-size: 13px;
}
#checkout .arrivalTime {
    font-weight: 600;
    font-size: 17px;
}
#checkout .arrivalDate {
    font-size: 13px;
    color: grey;
}
#checkout .angotrans {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: grey;
}
#checkout .angoDuration {
    display: none;
}
@media screen and (max-width: 450px) {
    #checkout .angotrans {
        display: none;
    }
    #checkout .angoDuration {
        display: block;
        font-size: 13px;
    }
}
#checkout .angotrans svg {
    color: #c40000;
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
}


#checkout .angotrans img {
    width: 130px;
    border-radius: 15px;
    height: 60px;
    object-fit: contain;
}

#checkout .arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
@media screen and (max-width: 520px) {
    #checkout .arrows {
        flex-direction: column;
        
    }
}
#checkout .arrows svg {
    width: 20px;
    height: 20px;
}
#checkout .horarioPrices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#checkout .horarioPrice {
    color: #c40000;
    font-weight: 700;
    font-size: 14px;
}

#checkout .bookButton {
    background-color: #c40000;
    color: white;
    padding: 20px;
    margin-bottom: -20px;
    margin-right: -20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
#checkout .bookButton:hover {
    opacity: 0.7;
}
#checkout .subTitle {
    margin-block: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-weight: 600;
}
#checkout .subTitle svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 0.5;
    }
}
#checkout .subTitle span {
    font-weight: 500;
}

#checkout .form {
    background-color: white;
    padding: 10px;
    
    border-radius: 10px;
    /* box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.158); */
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffffd7;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    filter: blur(0.1);
    margin-top: 20px;
    margin-inline: auto;
    
    
}
#checkout .form .tags {
    display: flex;
    flex-direction: row;
    gap: 10px;    
}

#checkout .form .tags .tag  {
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    background-color: #c40000;
    color: white;
    transition: 0.1s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
}
#checkout .form .tags .tag1  {
    padding: 10px 20px;
    background-color: #e2e2e2c9;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #3f3f3f;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

}
#checkout .form .select {
    outline: none;
    box-shadow: none;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
}

#checkout .form .inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

#checkout .loginInput {
    flex: 1;
    min-width: 200px;
    max-width: max-content;
}

#checkout .continue {
    background-color: #c40000;
    color: white;
    padding: 20px;
    margin-bottom: -20px;
    margin-right: -20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    width: 300px;
    margin-top: 20px;
    text-align: center;
}
#checkout .continue:hover {
    opacity: 0.7;
}