#interpro {
    margin-inline: 10px;
}
#interpro .title {
    font-weight: 600;
    font-size: 20px;
}
@media screen and (max-width: 600px) {
    #interpro  .title {
        font-size: 17px;
    }
}
#interpro .form {
    background-color: white;
    padding: 10px;
    
    border-radius: 20px;
    box-shadow: 0px 2px 15px 5px #63636336;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffffd7;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    filter: blur(0.1);
    margin-top: 20px;
    margin-inline: auto;
    
    
}
#interpro .form .tags {
    display: flex;
    flex-direction: row;
    gap: 10px;    
}

#interpro .form .tags .tag  {
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    background-color: #c40000;
    color: white;
    transition: 0.1s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
}
#interpro .form .tags .tag1  {
    padding: 10px 20px;
    background-color: #e2e2e2c9;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #3f3f3f;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

}
#interpro .form .select {
    outline: none;
    box-shadow: none;
    flex: 1;
    min-width: 200px;
}

#interpro .form .inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

#interpro .loginInput {
    flex: 1;
    min-width: 200px;
}

#interpro .form .button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid red;
    border-radius: 0.6em;
    color: red;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    border-color: #c40000;
    color: #fff;
    box-shadow: 0 0 40px 40px #c40000 inset, 0 0 0 0 red;
    transition: all 150ms ease-in-out;
    padding: 7px 15px;
    font-size: 14px;
    &:hover,
    &:focus {
        color: #c40000;
        outline: 0;
        box-shadow: 0 0 10px 0 #c4000000 inset, 0 0 10px 4px #c4000071;

    }
}
.interContainer {
    max-width: 1600px;
    margin-inline: auto;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
#interpro .locations {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 20px;
}
@media screen and (max-width: 1150px) {
    #interpro .locations {
        grid-template-columns: repeat(3, 1fr);
        
    }
}
@media screen and (max-width: 950px) {
    #interpro .locations {
        grid-template-columns: repeat(2, 1fr);
        
    }
}
@media screen and (max-width: 470px) {
    #interpro .locations {
        grid-template-columns: repeat(1, 1fr);
        
    }
}

#interpro .location {
    background-color: #eeeeee;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    aspect-ratio: 4/3.7;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;

}
#interpro .location .imageContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
#interpro .location img {
    width: 100%;
    height: 100%;
    filter: brightness(0.7);
    object-fit: cover;
    transition: transform 1.5s ease-in-out;
    transform: scale(1.4);

}
#interpro .location:hover img {
    filter: brightness(1);
    transform: scale(1) 
}

#interpro .location .info {
    /* color: #dacebe; */
    color: white;
    padding: 10px;
    transition: 0.3s ease-in-out;
    background-color: #ffffff49;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    filter: blur(0.1);
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

}
#interpro .location .locationTitle {
    font-weight: 600;
    font-size: 17px;
}
#interpro .location .locationDescription {
    font-size: 14px;
}
#interpro .location .priceRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    margin-top: 10px;
}
#interpro .location .price {
    font-weight: 800;
}
#interpro .location:hover .info {
    /* background-color: #610002;
    color: #dacebe; */
}

#interpro .location .button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border-radius: 0.6em;
    color: #dacebe;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    border-color: #5c3737;
    box-shadow: 0 0 40px 40px rgba(0, 0, 0, 0.192) inset, 0 0 0 0 rgba(0, 0, 0, 0.548);
    transition: all 150ms ease-in-out;
    padding: 7px 15px;
    font-size: 14px;
    transition: 0.3s ease-in-out;
    
}
#interpro .location:hover .button {
    border-color: #c40000;
    color: white;
    box-shadow: 0 0 40px 40px #c40000 inset, 0 0 0 0 #c40000;
}
#interpro .locationDuration {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-block: 5px;
    align-items: center;
}
#interpro .locationDuration svg {
    height: 20px;
    width: 20px;
}
#interpro .locationDuration .ini {
    font-weight: 500;
    font-size: 17px;
}
#interpro .locationDuration .duration {
    font-weight: 500;
    font-size: 14px;
}
#interpro .locationDuration .final {
    font-weight: 500;
    font-size: 17px
}

 
.historyContainer {
    padding: 15px 0;
    margin: 0px 0;
    position: relative;
    overflow: hidden;
    /* max-width: 2300px; */
}
 
 .historyContainer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 49.9%;
    margin-left: -1px;
    width: 4px;
    height: 100%;
    background: #c40000;
    z-index: 1
 }
 
 .timeline-block {
    width: -webkit-calc(50% + 9px);
    width: -moz-calc(50% + 9px);
    width: calc(50% + 9px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
    
 }
 
 .timeline-block-right {
    float: right;
 }
 
 .timeline-block-left {
    float: left;
    direction: rtl;

    .timeline-content {
        text-align: center;
        direction: ltr;
    }
 }
 
 .marker {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid white;
    background: #c40000;
    margin-top: 10px;
    z-index: 100
 }
 
 .timeline-content {
    width: 95%;
    padding: 20px;
    color: #666;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    margin: 0 5px;
    text-align: center;
 }
 
 .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500
 }
 
 .timeline-content span {
    font-size: 15px;
    color: #c40000;
    font-weight: 500;
 }
 
 .timeline-content p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
    color: #888;
 }
 
 
.historyContainer:before {
    left: 9.5px;
    width: 3px;
}
.timeline-block {
    width: 100%;
    margin-bottom: 30px;
}
.timeline-block-right {
    float: none;
    .timeline-content {
    text-align: left;
    direction: ltr;
}
}

.timeline-block-left {
    float: none;
    direction: ltr;

    .timeline-content {
    text-align: left;
    direction: ltr;
}
}
 
.dialog {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    margin-top: 15px;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 80vh;
}
.dialog .values {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    min-width: 160px;
}
.dialog .value {
    display: flex;
    flex-direction: column;
}
.dialog .value label {
    font-size: 12px;
    color: grey;
    font-weight: 500;
}
.dialog .value span {
    font-size: 14px;
    font-weight: 500;
}

.dialog .price {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 15px 15px;
    background-color: #eeeeee;
    font-size: 14px;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 10px;
    width: max-content;
    align-items: center;
}
.dialog .price .label {
    font-weight: 500;
    color: grey;
}
.dialog .price span {
    font-weight: 600;
    font-size: 16px;
    color: #c40000;
}

@keyframes loadingShimmer {
    0% {
      background-position: -100% 0;
    }
  
    100% {
      background-position: 200% 0;
    }
  }

.dialog .obs .label {
    font-size: 12px;
    color: grey;
    font-weight: 500;
}
.dialog .obs p {
    font-size: 14px;
    font-weight: 500;
    color: #c40000;
}

.dialog .conditions {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    min-width: 160px;

}
.dialog ul {
    text-decoration: dotted;
    list-style: circle;
    margin-top: 5px;
    background-color: #eeeeee;
    padding: 15px;
    border-radius: 10px;
    padding-left: 25px;
}
.dialog ul li {
    font-weight: 400;
    font-size: 13px;
}

#interpro .list {
    /* display: flex;
    flex-direction: column; */
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 1000px) {
    #interpro .list {
        
        grid-template-columns: repeat(1, 1fr);
    }
    
}

#interpro .horario {
    box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.158);
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 20px;
}
#interpro .horarioTitle {
    font-size: 15px;
    font-weight: 600;
}
#interpro .arrivals {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#interpro .arrival {
    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow: hidden;
}

#interpro .arrival span {
    color: grey;
    font-size: 13px;
}
#interpro .arrivalTime {
    font-weight: 600;
    font-size: 17px;
}
#interpro .arrivalDate {
    font-size: 13px;
    color: grey;
}
#interpro .angotrans {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: grey;
}
#interpro .angoDuration {
    display: none;
}
@media screen and (max-width: 450px) {
    #interpro .angotrans {
        display: none;
    }
    #interpro .angoDuration {
        display: block;
        font-size: 13px;
    }
}
#interpro .angotrans svg {
    color: #c40000;
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
}


#interpro .angotrans img {
    width: 130px;
    border-radius: 15px;
    height: 60px;
    object-fit: contain;
}

#interpro .arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
@media screen and (max-width: 520px) {
    #interpro .arrows {
        flex-direction: column;
        
    }
}
#interpro .arrows svg {
    width: 20px;
    height: 20px;
}
#interpro .horarioPrices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#interpro .horarioPrice {
    color: #c40000;
    font-weight: 700;
    font-size: 14px;
}

#interpro .bookButton {
    background-color: #c40000;
    color: white;
    padding: 20px;
    margin-bottom: -20px;
    margin-right: -20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
#interpro .bookButton:hover {
    opacity: 0.7;
}
#interpro .subTitle {
    margin-block: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
#interpro .subTitle svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 0.5;
    }
}
#interpro .subTitle span {
    font-weight: 500;
}


