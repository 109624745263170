#adminAbout{
    height: 100%;
    max-height: 1000%;
.first {
    display: flex;
    flex-direction: row;
    gap: 15px;
    
    @media screen and (width < 1100px) {
        flex-direction: column;
    }
    .section {
        border-radius: 7px;
        overflow: hidden;
        flex: 1.2;
        border: 1px solid #dfdfdf;
        display: flex;
        flex-direction: column;
        @media screen and (width < 1100px) {
            flex: none;
        }
        
        .missions {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;

            .mission {
                display: flex;
                flex-direction: column;
                gap: 7px;
                border: 1px solid #dfdfdf;
                padding: 10px;
                img {
                    width: 30px;
                    height: 30px;
                }
                .title {
                    font-weight: 500;
                    font-size: 15px;
                }
                .description {
                    color: grey;
                    font-size: 14px;
                }
            }
        }
        
        .adminAboutContainer {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 10px;
            gap: 20px;

            @media screen and (width < 1550px) {
                flex-direction: column;
                gap: 20px;
            }
            @media screen and (width < 700px) {
                padding-left: 0;
                padding-right: 0;
            }
            .info {
                display: flex;
                flex-direction: column;
                align-self: flex-start;
                padding: 0 10px;
                @media screen and (width >= 1490px){
                    justify-content: space-between;
                    max-height: 200px;
                    height: 100%;
                }
                @media screen and (width < 1550px) {
                    padding: 0 10px;
                }
            }
            
            .preTitle {
                color: grey;
                font-weight: 500;
                font-size: 14px;
                
            }
            .info > .title {
                position: relative;
                margin-top: 20px;
                font-size: 17px;
                font-weight: 500;
                color: #023047;
            }
            .info > .description {
                color: #3b3b3b;
                font-size: 14px;
            
                
            }
            .info > .title::before {
                position: absolute;
                content: "";
                border-top: solid 8px #c40000;
                border-radius: 100px;
                max-width: 300px;
                width: 100%;
                min-width: 100px;
                top: -10px;
                
            }
        }
        .imgContainer {
            flex: 1.2;
            border-radius: 10px;
            overflow: hidden;
            aspect-ratio: 4/2;
            margin: 0 10px;

            @media screen and (width < 1550px) {
                margin: 0 10px;
                border-radius: 10px;
                max-height: 400px;
        
            }
            @media screen and (width < 590px) {
                margin: 0 0;
                border-radius: 0;
            }
        
        }
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            
        }
        
        .header {
            background-color: #f7f7f7;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 8px 10px;
            border-bottom: 1px solid #dfdfdf;
            flex-wrap: wrap;

            .label {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 10px;
                .title {
                    font-size: 13px;
                    font-weight: 500;
                }
                svg {
                   width: 13px;
                   height: 13px; 
                }
            }
            .editCont {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                border: 1px solid #dfdfdf;
                padding: 0 10px;
                border-radius: 3px;
                font-size: 13px;
                cursor: pointer;
                &:hover {
                    opacity: 0.7;
                }
                svg {
                    width: 13px;
                    height: 13px;
                }
            }
        }
        .videoCont {
            background-color: #ececec;
            margin: 10px;
            aspect-ratio: 4/2;
            max-height: 350px;
            border-radius: 3px;
            overflow: hidden;
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        > div {
            @media screen and (width < 900px) {
                flex-direction: column;
            }
        }
        > div > div  {
            
            @media screen and (width < 900px) {
                max-height: 100%;
                max-height: 250px;

            }
        }
        .textCont {
            width: 100%;
            max-height: 0px;
            @media screen and (width < 900px) {
                max-height: 300px;
            }
            font-size: 14px;
            }
        
        .revText {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* Number of lines to display */
            overflow: hidden;
            text-overflow: ellipsis;
            
        }
        
    }
    
    .tableActions {

        /* text-align: center;
        border-left: 1px solid #e9e9e9;
        z-index: 1000;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 90px; */
        

    }
    .actionButtons {
        display: flex;
        flex-direction: row;
        gap: 5px;
    
        .actionButton {
            
            background-color: #eeeeee;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #d3d3d3;
            cursor: pointer;
            &:hover {
                opacity: 0.5;
            }
        }
    }
    
}

}
.sectionForms {
    margin-top: 20px;

    .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        width: 100%;
    }
    .label {
        font-weight: 500;
        font-size: 14px;
    }
    .imageInput1 {

    }
    .imageInput1 {
        color: transparent;
      }
      .imageInput1::-webkit-file-upload-button {
        visibility: hidden;
      }
      .imageInput1::before {
        content: 'Seleccione uma imagem';
        color: black;
        display: inline-block;
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 500;
        font-size: 13px;
      }
      .imageInput1:hover::before {
        border-color: black;
      }
      .imageInput1:active {
        outline: 0;
      }
      

      .imageInput2 {
        color: transparent;
      }
      .imageInput2::-webkit-file-upload-button {
        visibility: hidden;
      }
      .imageInput2::before {
        content: 'Seleccione um vídeo';
        color: black;
        display: inline-block;
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 500;
        font-size: 13px;
      }
      .imageInput2:hover::before {
        border-color: black;
      }
      .imageInput2:active {
        outline: 0;
      }

      .imageInput3 {
        color: transparent;
      }
      .imageInput3::-webkit-file-upload-button {
        visibility: hidden;
      }
      .imageInput3::before {
        content: 'Seleccione um PDF';
        color: black;
        display: inline-block;
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 500;
        font-size: 13px;
      }
      .imageInput3:hover::before {
        border-color: black;
      }
      .imageInput3:active {
        outline: 0;
      }
      
      .imageInput4 {
        color: transparent;
      }
      .imageInput4::-webkit-file-upload-button {
        visibility: hidden;
      }
      .imageInput4::before {
        content: 'Adicione uma imagem';
        color: black;
        display: inline-block;
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 500;
        font-size: 13px;
      }
      .imageInput4:hover::before {
        border-color: black;
      }
      .imageInput4:active {
        outline: 0;
      }

    .previewImages {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        @media screen and (width < 700px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .previewImageCont {
        aspect-ratio: 4/2.2;
        background-color: #e3e3e3;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        svg {
            position: absolute;
            z-index: 10;
            right: 5px;
            top: 5px;
            width: 30px;
            height: 30px;
            color: black;
            background-color: white;
            border-radius: 500px;
            cursor: pointer;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.8);
        }
    }
      
    .selectedImage {
        aspect-ratio: 4/2;
        width: 100%;
        background-color: #d3d3d3;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #b9b5b5;
        border-style: dashed;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .selectedVideo {
        aspect-ratio: 4/2;
        width: 100%;
        background-color: #d3d3d3;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #b9b5b5;
        border-style: dashed;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .textAreaContainer {

    }
    .buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-left: 5px;
        font-size: 14px;
        margin-top: 20px;
    }

    .cancel {
        color: grey;
        cursor: pointer;
        &:hover {
            color: black;
        }
    }
    .save {
        background-color: black;
        padding: 2px 7px;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        max-width: 150px;
        width: 100%;
        text-align: center;
        &:hover {
            opacity: 0.8;
        }
    }
    .loginInput {
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        height: 35px;
        width: 100%;
        outline: none;
        padding: 5px;
        font-size: 14px;

    }
    .loginButton {
        margin-top: 20px;
        background-color: #c40000;
        width: 100px;
        text-align: center;
        color: white;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        
    }
    .errors {
        color: #e70000;
        margin-top: 15px;
        font-size: 14px;
    }

    /*  */

.first {
    @media screen and (width < 1100px) {
        flex-direction: column;

    }
    .section {
        background-color: red;
    }

}



}