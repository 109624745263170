#about .swiper {
    width: calc(100% - 30px);
    aspect-ratio: 4/2;
    z-index: 5;
    position: relative;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    margin-top: 80px;
    max-width: 1600px;
    border-radius: 20px;
    max-height: 400px;
}
.slideTextCont {
    display: none;
}
#about .swiper::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    /* background-image: linear-gradient(to bottom, transparent 95%, rgb(255, 255, 255) 100%); */
    pointer-events: none;
    z-index: 100;


    
  }
  #about .swiper-slide {
  }
#about .swiper-slide img, .swiper video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.8);
    z-index: 10;
    
  }
#about .swiper video {
    filter: brightness(0.6);
  }
#about .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 101; /* Merged z-index values */
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #0E8EB3;
    display: none;
}
#about .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #0E8EB3;
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
    display: none;
  }
#about .swiper-button-next,
#about .swiper-button-prev {
    display: none;
    visibility: hidden;
}
#about .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    background-color: rgba(56, 56, 56, 0.808);
    color: #afafaff3;
    border: none;
    padding: 10px;
    border-radius: 500px;
    opacity: 0.5;
}
#about .button.left {
    left: 10px;
  }
  
#about .button.right {
    right: 10px;
  }
#about .button > svg {
    width: 25px;
    height: 25px;
}
@keyframes loadingShimmer {
    0% {
      background-position: -100% 0;
    }
  
    100% {
      background-position: 200% 0;
    }
  }
.aboutContainer {
  max-width: 1600px;
  margin: auto;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 15px;
}
.aboutContainer .left {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.aboutContainer .right {
  /* background-color: #eeeeee; */
  flex: 1;
}

.aboutContainer .sideMenu {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  border-radius: 10px;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  background-color: white;
}

.aboutContainer .sideMenu .menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  padding: 7px 10px;
  margin-block: 5px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.aboutContainer .sideMenu .menuItem:hover {
  background-color: #F6D7D7;
  color:#C40000;
}
.aboutContainer .sideMenu svg {
}
.aboutContainer .sideMenu hr {
  margin-inline: 5px;
}

.aboutContainer .sideMenu .menuItem1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  padding: 7px 10px;
  margin-block: 5px;
  background-color: #F6D7D7;
  border-radius: 5px;
  color: #C40000;
  cursor: pointer;

}
.aboutContainer .right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.aboutContainer .right .aboutSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
}

.aboutSection .aboutInfo {
  flex: 3;
  height: 100%;
}
.aboutSection .aboutImageContainer {
  flex: 1;
  height: 100%;
  /* background-color: #5c5c5c;
  background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingShimmer;
  animation-timing-function: linear;
  background-color: #efefef;
  background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
  background-repeat: no-repeat;
  background-size: 50%; */
  box-sizing: border-box;
  background: #f2ebfb; 
  width: inherit;
  padding-bottom: 50px; 
  background-image: linear-gradient(#f3f3f3ce, #f3f3f3ce), url('./landscape.svg');
  background-size: 200px;
  background-repeat: repeat;
  
}
@media screen and (max-width: 585px) {
  .aboutSection .aboutImageContainer {
    display: none;
  }
}
.aboutSection {
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  border-radius: 10px;
  overflow: hidden;
}
.aboutInfo {
  padding: 15px;
}
.aboutInfo .title {
  font-size: 18px;
  font-weight: 600;
  color: #1f5baf;
  position: relative;
  margin-top: 20px;
  color:#C40000;
  
}
.aboutInfo .title::before {
  position: absolute;
  content: "";
  border-top: solid 6px #C40000;
  border-radius: 100px;
  max-width: 100px;
  width: 100%;
  min-width: 100px;
  top: -10px;
  
}
.aboutInfo p {
  font-size: 13px;
  color: grey;
  margin-top: 20px;
}
.aboutContainer .values {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
.aboutContainer .services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

@media screen and (max-width: 1330px) {
  .aboutContainer .values {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 994px) {
  .aboutContainer .values {
    grid-template-columns: repeat(2, 1fr);
  }

}
@media screen and (max-width: 425px) {
  .aboutContainer .values {
    grid-template-columns: repeat(1, 1fr);
  }

}
@media screen and (max-width: 810px) {
  .aboutContainer .services {
    grid-template-columns: repeat(1, 1fr);
  }
}
.aboutContainer .value {
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  padding: 15px;
  display: flex;
  flex-direction: column;
  
}
.aboutContainer .value svg {
  background-color: #F6D7D7;
  color: #C40000;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 1.7px solid #C40000;
  border-radius: 5px;
}
.aboutContainer .valueTitle {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.aboutContainer .valueDescription {
  color: grey;
  font-size: 12px;
  margin-top: 15px;
}

.aboutContainer .images {
  display: grid;
  gap: 15px;
  margin-top: 20px;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 1180px) {
  .aboutContainer .images {
    
    grid-template-columns: repeat(1, 1fr);
  }
}
.aboutContainer .imageContainer {
  aspect-ratio: 4/3;
  flex: 1;
  display: relative;
  /* background-color: red; */
  background: transparent;
  border-radius: 5px;
  overflow: hidden;
  background-color: #5c5c5c;
  background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingShimmer;
  animation-timing-function: linear;
  background-color: #efefef;
  background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
  background-repeat: no-repeat;
  background-size: 50%;
  position: relative;
  max-height: 400px;
  width: 100%;
}

.aboutContainer .imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
  z-index: 1;
  position: absolute;
  


}
.aboutContainer .imageContainer .info {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  margin: 10px;
  background-color: #ffffff49;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  filter: blur(0.1);
  padding: 10px;
  border-radius: 5px;
}
.aboutContainer .imageContainer .infoTitle {
  font-size: 16px;
  font-weight: 600;
}
.aboutContainer .imageContainer .infoDescription {
  font-size: 13px;
  margin-top: 20px;
}
.aboutContainer .contactsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.aboutContainer .contacts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  min-width: 250px;

}
.aboutContainer .contact {
  display: flex;
  flex-direction: column;
}
.aboutContainer .contact label{
  font-size: 12px;
  font-weight: 500;
}
.aboutContainer .contact span {
  font-size: 14px;
  color: grey;
}

.aboutContainer iframe {
  border-radius: 5px;
  overflow: hidden;
  border: 5px solid #ececec;
  width: 100%;
  height: 100%;
}
.aboutContainer .iframe {
  flex: 4;
  /* background-color: red; */
  width: 100%;
  aspect-ratio: 4/2.5;
  min-width: 250px;
}

.aboutOptions {
  position: relative; 
  max-width: 300px;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 0;
  display: none;
  
}
@media screen and (max-width: 862px) {
  .aboutContainer .left {
    display: none;
  }
  .aboutOptions {
    display: unset;
  }
}
.aboutOptionsButton {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  color: #C40000;
  border-radius: 5px;
  width: 100%;
  justify-content: center;
  border: 2px solid #C40000;
  font-size: 14px;
  outline: none;
  
}

.aboutOptionsList {
  background-color: white;
  position: absolute;
  width: 100%;
  margin-top: 10px;
  border: 0.6px solid #cccccc;
  border-radius: 10px;
  max-width: 300px;
  
}
.optionItem {
  color: black;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.optionItem1 {
  color: white;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 5px;
  background-color: #e69500;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #F6D7D7;
  color:#C40000;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

}







