.header {
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* background-color: #ffffffbb; */
    background-color: #790000b9;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    filter: blur(0.1);
    /* border-bottom-left-radius: 20px;    border-bottom-right-radius: 20px; */

}
@media screen and (max-width: 400px) {
    .header {
        justify-content: space-between;
        height: 60px;
    }
}
.header .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    max-width: 1600px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
    background: transparent;
    transition: 0.3s ease-in-out;  
    box-sizing: border-box;
    /* background-color: #c4d9ff; */
    justify-content: space-between;
}
.logoTextCont {
    cursor: pointer;
    
}

/* Media Queries */
@media screen and (max-width: 1180px) {
    .logoTextCont {
    }
}

@media screen and (max-width: 400px) {
    .logoTextCont {
        display: none;
    }
}
.logoText {
    font-weight: 900;
    font-size: 15px;
    text-transform: uppercase;
    max-width: 300px;
    margin-left: 0;
    margin-right: 20px;
    color: #c40000;
}

/* Media Queries */
@media screen and (max-width: 1300px) {
    .logoText {
        flex: 1;
        font-size: 15px;
    }
}

@media screen and (max-width: 550px) {
    .logoText {
        font-size: 13px;
    }
}

/* grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]  */

.header .navigationTriggers {
    background-color: transparent;
    transition: 0.2s ease-in-out;
    color: white;
}
.header .navigationTriggers:hover {
    background-color: #c4000028;
    /* color: #c40000; */
    color: white;
}
.header .navigationTriggers1 {
    background-color: #c4000028;
    /* color: #c40000; */
    color: white;
}
.header .navigationTriggers1:hover {
    background-color: #c4000028;
    /* color: #c40000; */
    color: white;
}

.header .aboutMenu {
    width: 200px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
}
.header .aboutMenu .menuItem {
    font-size: 13px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
}
.header .aboutMenu .menuItem:hover {
    color: #c40000;
    background-color: #c4000028;
    
}
.header .menu2 {
    padding: 10px;
    width: 300px;
    gap: 15px;
    display: flex;
    flex-direction: column;
}
.header .listItem {
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}
.header .listItem:last-child {
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    height: 40px; 
}
.header .listItem:hover {
    color: #c40000;
    background-color: #c4000028;
}
.sideLogo {
    width: 150px;
    margin-left: -20px;
    height: 95px;
}
.sideMenuContainer {
    padding-inline: 15px;
}
.sideMenuItems {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}
.sideMenuItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    margin-block: 5px;
    cursor: pointer;
    
}
.sideMenuItem05 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 10px;
    margin-block: 5px;
    cursor: pointer;  
}
.sideMenuItem05 div:first-child {
    font-weight: 500;

}


.sideMenuItem3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    margin-block: 5px;
    background-color: #c4000060;
    border-radius: 5px;
    color: #c40000b7;
    cursor: pointer;
    
}
.bi-list {
    display: none;
}
@media screen and (max-width: 800px) {
    .navMenu {
        display: none;
    }
    .bi-list {
        display: block;
    }
    
}
@media screen and (max-width: 500px) {
    .header {
        background-color: white;
    }

}