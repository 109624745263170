#newsletter .bannerContainer {
    overflow: hidden;
    height: 70vh;
    position: relative;
    min-height: 500px;
}
@media screen and (max-width: 750px) {
    #newsletter .bannerContainer {
        overflow: hidden;
        height: 70vh;
        position: relative;
        min-height: 700px;
    }
}
#newsletter .bannerContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    filter: brightness(0.7);
}
#newsletter .info {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 20%;
    top: 20%;
    right: 3%;
    left: 3%;
    gap: 20px;
}
@media screen and (max-width: 1000px) {
    #newsletter .text {
        font-size: 22px;
    }
}
@media screen and (max-width: 750px) {
    
    #newsletter .info {
        flex-direction: column;
        align-items: center;

    }

    

}

#newsletter .text {
    color: white;
    font-size: 25px;
    font-weight: 700;
    max-width: 500px;
    text-align: left;
    flex: 1;
}
@media screen and (max-width: 750px) {
    
    #newsletter .text {
        text-align: center;
        font-size: 18px;
    }

    

}
@media screen and (max-width: 500px) {
    
    #newsletter .text {
        text-align: center;
        font-size: 16px;
    }

    

}
#newsletter .forms {
    flex: 1;
    padding: 30px;
    border-radius: 20px;
    background-color: #ffffffad;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    filter: blur(0.1);
    width: 100%;
    min-width: 200px;
    height: 400px;
    max-width: 400px;
    margin-top: -45px;
}
@media screen and (max-width: 750px) {
    #newsletter .forms {
        margin-top: 0;
        margin-inline: 10px;
    }

}
#newsletter .errors {
    color: #e70000;
    margin-block: 15px;
    margin-top: -15px;
    font-size: 14px;
}
#newsletter .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}
#newsletter .label {
    font-weight: 500;
    font-size: 13px;
    font-weight: 500;
}
#newsletter .loginInput {
    height: 35px;
    width: 100%;
    outline: none;
    padding: 5px;
    font-size: 13px;
    background-color: #ffffff00;
    border: none;
    

}
#newsletter .inputContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 0px solid #ffffff2a;
    background-color: #ffffff2a;
    border-radius: 5px;
    cursor: pointer;
}
#newsletter .inputContainer svg {
    border-right: 1px solid #a8a8a8;
    width: 34px;
    color: grey;
    transform: scale(0.9);
}
#newsletter .loginInput::placeholder {
    color: black;
}
#newsletter .inputRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    
}
#newsletter .inputRow svg {
    height: 35px;
    width: 35px;
    margin-left: 5px;
    cursor: pointer;
    background-color: #e2e2e2;
    padding: 8px;
    border-radius: 5px;
    color: grey;
    &:hover {
        color: black;
    }
}
#newsletter .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: 5px;
    font-size: 14px;
}
#newsletter .cancel {
    color: grey;
    cursor: pointer;
    &:hover {
        color: black;
    }
}
#newsletter .button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid red;
    border-radius: 0.6em;
    color: red;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    border-color: #c40000;
    color: #fff;
    box-shadow: 0 0 40px 40px #c40000 inset, 0 0 0 0 red;
    transition: all 150ms ease-in-out;
    padding: 7px 15px;
    font-size: 14px;
    display: inline;
    &:hover,
    &:focus {
        color: #c40000;
        outline: 0;
        box-shadow: 0 0 10px 0 #c4000000 inset, 0 0 10px 4px #c4000083;

    }
}