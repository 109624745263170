.dashboardContainer {


#first1{
    /* background-color: red; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (width < 1300px) {
        grid-template-columns: repeat(2, 1fr);

    }
    @media screen and (width < 1000px) {
        grid-template-columns: repeat(1, 1fr);

    }

    .valueCont {
        font-size: 30px;
        font-weight: 600;
        padding: 10px;
    }
    .value {
        box-shadow: none;
        text-align: center;
    }
.info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .basicInfo {
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4; /* Number of lines to display */
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px;
        padding-bottom: 0;
    
    }
}
}

#first2{
    /* background-color: red; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (width < 1300px) {
        grid-template-columns: repeat(2, 1fr);

    }
    @media screen and (width < 1000px) {
        grid-template-columns: repeat(1, 1fr);

    }

    .valueCont {
        font-size: 30px;
        font-weight: 600;
        padding: 10px;
    }
    .value {
        box-shadow: none;
        text-align: center;
    }
.info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .basicInfo {
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4; /* Number of lines to display */
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px;
        padding-bottom: 0;
    
    }
}
}
}

