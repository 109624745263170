.homeSection {
    max-width: 1600px;
    margin: auto;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 15px;
}
.homeSection .description, .homeSection .title {
    text-align: center;
}
.homeSection .title {
    font-size: 25px;
    font-weight: 800;
}
.homeSection .description {
    color: grey;
    font-weight: 500;
    margin-bottom: -20px;
}
#services .cards {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
}
#services .cards .card {
    aspect-ratio: 4/1.7;
    display: flex;
    align-items: center;
    flex-direction: column;
    /*     background-color: #eeeeee83;
 */
    background-color: #C9AF91;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    /* box-shadow: 0px 2px 15px 5px #ececec36; */
    flex: 1;
    transition: 0.2s ease-in-out;
    min-width: 300px;
    color: white;
}
@media screen and (max-width: 450px) {
    #services .cards .card {
        aspect-ratio: 4/1.7;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #C9AF91;
        padding: 15px;
        border-radius: 10px;
        justify-content: space-between;
        box-shadow: 0px 2px 15px 5px #ececec36;
        flex: 1;
        transition: 0.2s ease-in-out;
        min-width: 250px;
        min-height: 350px;

    }
}

#services .cards .card:hover {
    background-color: #6b0000;
    color: white;
}

#services .cards .card .cardTitle {
    font-size: 18px;
    font-weight: 700;
}
#services .cards .card .cardDescription {
    font-size: 14px;
}
#services  .secondImageContainer {

}
#services  .secondImageContainer img{
    object-fit: contain;
    width: 90%;
    height: 100%;
    margin: auto;
}
#services .cards .imageContainers {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
#services .cards .subBox {
    padding: 10px;
    box-shadow: 0px 2px 15px 5px #c0c0c036;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s ease-in-out;
}
#services .cards .subBox:hover {
    background-color: #c40000;
    color: white;
}

#services .cards .subTitle {
    font-weight: 600;
}
#services .cards .imageContainer img{
    object-fit: contain;
    width: 80%;
    height: 100%;
    margin: auto;
}
#services .seeMore {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    
}