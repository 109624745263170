#recommendations .destinations {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 20px;
}
@media screen and (max-width: 1150px) {
    #recommendations .destinations {
        grid-template-columns: repeat(3, 1fr);
        
    }
}
@media screen and (max-width: 950px) {
    #recommendations .destinations {
        grid-template-columns: repeat(2, 1fr);
        
    }
}
@media screen and (max-width: 470px) {
    #recommendations .destinations {
        grid-template-columns: repeat(1, 1fr);
        
    }
}

#recommendations .destination {
    background-color: #eeeeee;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    aspect-ratio: 4/3.7;

}
#recommendations .destination .imageContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
#recommendations .destination img {
    width: 100%;
    height: 100%;
    filter: brightness(0.7);
    object-fit: cover;
    transition: transform 1.5s ease-in-out;
    transform: scale(1.4);

}
#recommendations .destination:hover img {
    filter: brightness(1);
    transform: scale(1) 
}

#recommendations .destination .info {
    /* color: #dacebe; */
    color: white;
    padding: 10px;
    transition: 0.3s ease-in-out;
    background-color: #ffffff49;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    filter: blur(0.1);
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

}
#recommendations .destination .destTitle {
    font-weight: 600;
    font-size: 17px;
}
#recommendations .destination .destDescription {
    font-size: 14px;
}
#recommendations .destination .priceRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    margin-top: 10px;
}
#recommendations .destination .price {
    font-weight: 800;
}
#recommendations .destination:hover .info {
    /* background-color: #610002;
    color: #dacebe; */
}

#recommendations .destination .button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border-radius: 0.6em;
    color: #dacebe;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    border-color: #5c3737;
    box-shadow: 0 0 40px 40px rgba(0, 0, 0, 0.192) inset, 0 0 0 0 rgba(0, 0, 0, 0.548);
    transition: all 150ms ease-in-out;
    padding: 7px 15px;
    font-size: 14px;
    transition: 0.3s ease-in-out;
    
}
#recommendations .destination:hover .button {
    border-color: #c40000;
    color: white;
    box-shadow: 0 0 40px 40px #c40000 inset, 0 0 0 0 #c40000;
}
#recommendations .locationDuration {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-block: 5px;
    align-items: center;
}
#recommendations .locationDuration svg {
    height: 20px;
    width: 20px;
}
#recommendations .locationDuration .ini {
    font-weight: 500;
    font-size: 17px;
}
#recommendations .locationDuration .duration {
    font-weight: 500;
    font-size: 14px;
}
#recommendations .locationDuration .final {
    font-weight: 500;
    font-size: 17px
}