.ul {
    font-weight: 500;
}
.ul ul{
    text-decoration: dotted;
    padding: 0 20px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: grey;
    list-style: circle;
}
#interpro .routes .list {
    /* display: flex;
    flex-direction: column; */
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 1000px) {
    #interpro .routes .list {
        
        grid-template-columns: repeat(1, 1fr);
    }
    
}
