.homeBanner {
    width: 100vw;
    height: 85vh;
    position: relative;

}
.homeBanner .background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.homeBanner .hero {
    position: absolute;
    width: 300px;
    height: 300px;
    margin: auto;
    left: 0;
    right: 0;
    top: 80px;

}
.homeBanner .heroTitle {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    
}
.homeBanner .hero img {
    transform: scale(1.3);
    
}
.homeBanner .form {
    background-color: white;
    padding: 10px;
    width: 80vw;
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0px 2px 15px 5px #63636336;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffffd7;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    filter: blur(0.1);
    z-index: 11;
}
.homeBanner .form .tags {
    display: flex;
    flex-direction: row;
    gap: 10px;    
}

.homeBanner .form .tags .tag  {
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    background-color: #c40000;
    color: white;
    transition: 0.1s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
}
.homeBanner .form .tags .tag1  {
    padding: 10px 20px;
    background-color: #e2e2e2c9;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #3f3f3f;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

}
.homeBanner .form .select {
    outline: none;
    box-shadow: none;
    flex: 1;
    min-width: 200px;
}

.homeBanner .form .inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

 .homeBanner .loginInput {
    flex: 1;
    min-width: 200px;
}

.homeBanner .form .button1 {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid red;
    border-radius: 0.6em;
    color: red;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    border-color: #c40000;
    color: #fff;
    box-shadow: 0 0 40px 40px #c40000 inset, 0 0 0 0 red;
    transition: all 150ms ease-in-out;
    padding: 7px 15px;
    font-size: 14px;
    &:hover,
    &:focus {
        color: #c40000;
        outline: 0;
        box-shadow: 0 0 10px 0 #c4000000 inset, 0 0 10px 4px #c4000071;

    }

    
    
}
.swiper {
    width: 100%;
    height: 100%;;
    z-index: 5;
    position: relative;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


.homeBanner .swiper::after {
    /* position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background-image: linear-gradient(to bottom, transparent 95%, rgb(255, 255, 255) 100%);
    pointer-events: none;
    z-index: 100; */

  }
  .homeBanner  .slideTextCont {
    position: absolute;
    z-index: 100;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;


  }
  .homeBanner  .slideText {
    margin: auto;
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    max-width: 70%;
}

/* Media Queries */
@media screen and (max-width: 925px) {
    .homeBanner .slideText {
        font-size: 30px;
    }
}

@media screen and (max-width: 780px) {
    .homeBanner .slideText {
        font-size: 25px;
    }
}

@media screen and (max-width: 580px) {
    .homeBanner .slideText {
        font-size: 18px;
    }
}
.homeBanner .swiper .swiper-slide img, .swiper video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    z-index: 10;
  }
  .homeBanner .swiper video {
    filter: brightness(0.6);
  }
  .homeBanner .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 101; /* Merged z-index values */
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #e9e9e9;
}

/* Media Queries */
@media screen and (max-width: 500px) {
    .homeBanner .autoplay-progress {
        width: 30px;
        height: 30px;
    }
}
  
.homeBanner .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #e9e9e9;
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }
  
  .homeBanner .swiper-button-next,
  .homeBanner .swiper-button-prev {
    display: none;
    visibility: hidden;
}
.homeBanner .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    background-color: rgba(56, 56, 56, 0.808);
    color: #e9e9e9;
    border: none;
    padding: 10px;
    border-radius: 10px;
    opacity: 0.5;
}

/* Media Queries */
@media screen and (max-width: 500px) {
    .homeBanner .button {
        display: none;
    }
}
  
.homeBanner .button.left {
    left: 10px;
  }
  
  .homeBanner .button.right {
    right: 10px;
  }
  .homeBanner .button > svg {
    width: 25px;
    height: 25px;
}



