#downloadContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-block: 50px;

}
@media screen and (max-width: 1000px) {
    #downloadContainer {
        
        margin-block: 40px;

    
    }
}
@media screen and (max-width: 1048px) {
    #downloadContainer {
        flex-direction: column;
    }
    #downloadContainer .hiddenImage{
        display: block;
        flex: 1;
        width: 100%;
        height: 100%;
        object-fit: contain;

    }
    #downloadContainer .hiddenImage{
        display: none;
    }
    #downloadContainer .right {
        display: none;
    }
    #downloadContainer .links > img {
        all: unset;
        flex: 1;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
    #downloadContainer .links  {
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 15px;
    }
    #downloadContainer .platforms  {
        align-items: center;
        align-self: center;
    }
    



   
}

#downloadContainer .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
#downloadContainer .right {
    flex: 1;
    /* background: radial-gradient(circle at center, #585858 0, #e6e6e6, #ffffff 100%); */
    overflow: visible;
    position: relative;
    z-index: 5;
    border-radius: 500px;
    width: 100%;

}
#downloadContainer .hiddenImage{
    display: none;
}
#downloadContainer .right img {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    
    min-width: 400px;
    min-width: 400px;
    max-height: 500px;

}

#downloadContainer > .left > .description {
    all: unset;
    font-size: 15px;
    color: grey;
    text-align: center;
    margin-top: 20px;
    padding-inline: 50px;
    max-width: 550px;
}
@media screen and (max-width: 800px) {
    #downloadContainer > .left > .description {
        padding-inline: 10px;
        

    }
}
#downloadContainer .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
#downloadContainer .links > img {
    flex: 1;
    aspect-ratio: 2/1.5;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 350px;
}
@media screen and (max-width: 450px) {
    #downloadContainer .links > img {
       
        min-width: 200px;
    }
}
#downloadContainer .platformContainer {
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    transition: 0.3s ease-in-out;
}
#downloadContainer .platformContainer:hover {
    background-color: black;
    color: white;
}
#downloadContainer .platformContainer:hover svg {
    color: black;
}
#downloadContainer .platformContainer:hover .platform {
    background-color: white;
    color: black;
}
#downloadContainer .platformContainer:hover .platformDescription, .platformDescription1 {
    color: white;
}


#downloadContainer .platformIcon {
    background-color: #EDEDED;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
}
#downloadContainer .platformIcon svg {
    width: 20px;
    height: 20px;
    transition: 0.3s ease-in-out;

}
#downloadContainer .platformDescription {
    font-size: 13px;
    margin-top: 25px;
    color: grey;
}
#downloadContainer .platformID {
    font-weight: 700;
    font-size: 20px;
}
#downloadContainer .platformDescription1 {
    font-size: 13px;
    color: #a5a5a5;
    margin-top: 20px;
    font-weight: 500;
}




#downloadContainer .platforms {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 450px) {
    #downloadContainer .platforms {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
#downloadContainer .platform {
    /* background: linear-gradient(-45deg, #010314, #010314, #330000, #010314); */
    background: black;
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    color: white;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    max-width: 130px;
    height: 40px;
    cursor: pointer;
    min-width: 130px;
    margin-top: 15px;
    transition: 0.3s ease-in-out;

}


#downloadContainer .platform .title {
    font-weight: 800;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: -4px;
}

#downloadContainer .platform svg {
    height: 80%;
    aspect-ratio: 1/1;
    width: 18px;

}

#downloadContainer .platform .info {
    flex: 1;
    text-align: center;
    align-self: flex-end;
}


#downloadContainer .platform .description {
    all: unset;
    font-size: 10px;
}






@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}